<template lang='pug'>
    v-container.custom-container(fluid)
        <!-- dialogs -->
        <!-- create -->
        v-dialog( persistent  :value='createItemDialog'  max-width='800px'   transition="dialog-bottom-transition")
            div(style ='position:relative; height: 100%;')
                create-item( @close="createItemDialog=false" v-if='createItemDialog' )
        <!-- update -->
        v-dialog( persistent :key='currentItemId' :value='updateItemDialog'  max-width='800px'   transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            update-item(:model ='currentItem' @close="updateItemDialog=false")
        <!-- delete -->
        v-dialog(v-model='confirmationDialog' width="500px" persistent)
            v-card
              v-card-title
                | Delete Collaborator
                v-spacer
                  v-icon(aria-label="Close" @click="$emit('close')")
                    | mdi-close
              v-card-text.body-1.text-center
                | ¿Delete Collaborator?
              v-card-actions.text-center
                  v-spacer
                    v-btn(color='green darken-1', flat='', @click.native='confirmationDialog = false') Cancel
                    v-btn(color='red darken-1', flat='', @click.native='deleteItem(currentItemId)') Yes, proceed
        <!-- content -->
        v-layout(wrap='')
            v-flex(md12='')
                base-material-card(icon='mdi-earth' title='Collaborator', style='width:100%')
                    v-row
                        v-col(cols='12' md='12').text-right
                            v-btn(small color="secondary" @click="createItemDialog=true").mx-2
                                | Create collaborator
                                v-icon mdi-plus
                    v-flex(md12='',)
                        v-row
                            v-col
                                | collaborators List
                            v-col
                                v-spacer
                            v-text-field(v-model='search', append-outer-icon='mdi-search', label='Search..', single-line='', hide-details='')
                        v-data-table(:headers='headers', :items='Items', :search='search')
                            template
                              v-card-text
                            template(v-slot:item.actions="{ item }")
                              v-btn(fab dark small color='green' @click.stop='editItem(item.id)')
                                  v-icon(small) mdi-pen
                              v-btn(fab dark small color='red'  @click.stop='openDeleteDialog(item.id)')
                                  v-icon(small) mdi-delete

</template>

<script>

  import loaders from '@/plugins/mixins/loaders'

  import CreateItem from './Create/CreateUser'
  import UpdateItem from './Update/UpdateUser'

  export default {
    components: {
      CreateItem: CreateItem,
      UpdateItem: UpdateItem,
    },
    mixins: [loaders],
    data () {
      return {
        search: '',
        createItemDialog: false,
        updateItemDialog: false,
        confirmationDialog: false,
        currentItemId: '',
        headers: [

          {
            sortable: true,
            text: 'Name',
            value: 'displayName',
            align: 'left',
          },
          {
            sortable: true,
            text: 'Email',
            value: 'email',
            align: 'left',
          },
          {
            sortable: true,
            text: 'Actions',
            value: 'actions',
            align: 'left',
          },
        ],
      }
    },
    computed: {
      Items () {
        return this.$store.getters['collaborators/getItems']
      },
      currentItem () {
        // console.log(this.$store.getters['collaborators/getCurrentItem'])
        return this.$store.getters['collaborators/getCurrentItem']
      },
    },
    created () {
      const vm = this
      vm.loadCollaborators()
    },
    methods: {
      openDeleteDialog (id) {
        // console.log(id)
        const vm = this
        vm.currentItemId = id
        vm.confirmationDialog = true
      },
      editItem (id) {
        const vm = this
        this.$store.dispatch('collaborators/setCurrentItem', { context: 'properties', id: id }).then(function () {
          vm.updateItemDialog = true
        })
      },
      deleteItem (id) {
        // console.log(id)
        const vm = this
        vm.$store.dispatch('collaborators/deleteItem', id)
          .then(function () {
            vm.confirmationDialog = false
            vm.dialog = false
          },
          ).catch(function () {
            vm.confirmationDialog = false
            vm.dialog = false
          })
      },

    },
  }
</script>
<style lang="scss">
    .hover-cursor{
        cursor:pointer;
    }
</style>
