<template lang='pug'>
  v-container(pa-0='' )
      v-snackbar(v-model='show' color='red' right top :timeout="4000")
        | Invalid format
        v-btn(dark='' text='' @click='show = false')
          | Close
      v-form(ref="form"  v-model="valid")
          v-layout
              v-flex.column()
                  v-flex
                      div(cols='8')
                          v-text-field(v-model='model.displayName', :counter='30', :rules='nameRules', label='Name', required='')
                          v-text-field(v-model='model.email' type='email' :rules='emailRules' label='Email' required='' :disabled='field.types === "update"')
                          v-text-field(v-model='model.password' type='password' :rules='pswRules' label='Password' required='' :disabled='field.types === "update"')
          v-btn(dark link @click="cancel()") Cancel
          v-btn(color='primary', @click='validate' :loading="isLoading"  :disabled='!model.displayName')
              | SAVE

</template>
<script>
  export default {
    props: {
      model: Object,
      field: Object,
      onSave: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        valid: true,
        valueType: '',
        show: false,
        isMobile: false,
        nameRules: [
          v => !!v || 'Required',
          v => (v && v.length <= 30) || 'Max length: 30 characters',
        ],
        emailRules: [
          v => !!v || 'Required',
          v => /.+@.+\..+/.test(v) || 'Email format',
        ],
        pswRules: [
          v => !!v || 'Required',
          v => (v && v.length >= 8) || 'min length: 8 characters',
        ],
      }
    },
    computed: {

      user () {
        return this.$store.state.users.user
      },
      isLoading () {
        return this.$store.state.collaborators.isLoading
      },

    },
    methods: {
      validate () {
        const vm = this
        vm.$store.commit('collaborators/SET_LOADING', true)
        if (vm.$refs.form.validate()) {
          if (vm.field.types !== 'update') {
            vm.save()
            vm.snackbar = true
          } else {
            vm.save()
          }
        } else {
          vm.$store.commit('collaborators/SET_LOADING', false)
          vm.show = true
        }
      },
      cancel () {
        this.$emit('cancel')
      },
      save () {
        this.model.role = 'Colaborador'
        this.onSave()
      },
    },
  }
</script>
<style scoped lang="scss">
    $break-large: 1200px;
    $primary-color: #c29f52;
    .grid-picker{
        text-align: center;
        min-width: 100%;
        display: grid;
        position: relative;
        flex: 1;
        width: 100%;
        grid-template-areas:

                'picture picture '
                'title title '
                'button button';
        @media screen and (min-width: $break-large) {
            grid-template-areas:

                    'title picture picture '
                    'title picture picture '
                    'button button button';
            .image-slot{
                min-width: 400px;
                height: 250px;
                overflow: hidden;
            }
        }

        grid-gap: 10px;

        padding: 10px;
        .picker-btn{

            grid-area: button;
            flex: 1;
            height: 50px;
        }
        .image-slot{
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            display: flex;
            grid-area: picture;
            max-width: 350px;
            max-height: 250px;
            background: #9e9e9e;
            align-items:center;
            justify-content: center;

        }
    }

    .phone-screen{

        background: url("/img/phone.png");
        display:flex;
        flex-direction: column;
        border-radius: 50px;
        border: 1px solid black;

        min-width: 379px;
        min-height: 736px;
        background-size:  379px 736px;
        background-repeat: no-repeat;
        margin:auto;
        overflow: hidden;
        &__content{

            overflow-y: auto;
            max-width: 382px;
            max-height: 595px;
            margin: auto !important;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 0 15px;
        }
    }
    .main-carousel{
        position: relative;
        &__overlay{
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            height: 45px;
            width: 100%;
            margin: auto !important;
            background:rgba(#000,0.5);
            color: #fff;
            text-align: center;

        }
    }
    .property-values{
        border: 1px solid #c29f52;
        border-radius: 30px;
        height: auto;
        padding: 0 10px;
        h3{
            color:#c29f52;
            font-weight: 900;

        }
        .price-tooltip{
            color:#c29f52;
            font-weight: bold;
            margin:auto;
            margin-top:-10px;
            max-width: 100px;
            border: 1px solid #c29f52;
            border-radius: 15px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top: none;
        }
    }
    .numPayments{
        background: white !important;
        border: 1px solid #777;
        border-radius: 25px;
        width:auto;
        max-width: fit-content;

        &__button{
            color:#000 !important;
            border-radius: 40px;
        }
    }
    .v-input--slider{
        margin-top:0;
    }
    .v-tabs__container{
        max-width: 100%;
        overflow-x: auto;

    }
    .property-sections{
        span,b{
            font-size: 15px !important;
        }

        .v-tab{
            color: black !important;
        }
    }
    .property-toolbar{

    }
</style>
