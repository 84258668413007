<template lang="pug">
  v-card
    v-snackbar(v-model='show' color='red' right top :timeout="4000")
        | {{message}}
        v-btn(dark='' text='' @click='show = false')
          | Close
    v-card-title
      | Create Collaborators
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.body-1.text-center
      v-row
        v-col(cols="12" md="12")
            v-layout
                formStepper(:model ='model', :field='field' :onSave='createItem' :context='currentContext' @cancel="cancel")
</template>
<script>
  import Stepper from '../../../components/custom/steppers/UserStepper'
  import axios from 'axios'
  import * as vars from '@/vars.json'
  let vm = null
  export default {
    components: {
      formStepper: Stepper,
    },
    data: () => ({
      model: {},
      field: {
        types: [{ name: 'Punto de venta', value: 'pos' }, { name: 'Bodega', value: 'warehouse' }],
      },
      currentContext: 'posts',
      message: '',
      show: false,
    }),
    computed: {
      icon () {
        const category = this.model.category
        let categoryIcon = null
        switch (category) {
          case 'nation':
            categoryIcon = 'mdi-alpha-n-box'
            break
          case 'economy':
            categoryIcon = 'mdi-alpha-e-box'
            break
          case 'life':
            categoryIcon = 'mdi-alpha-v-box'
            break
          case 'international':
            categoryIcon = 'mdi-alpha-i-box'
            break
          case 'trending':
            categoryIcon = 'mdi-alpha-v'
            break
          case 'sports':
            categoryIcon = 'mdi-alpha-d-box'
            break
          case 'entertainment':
            categoryIcon = 'mdi-alpha-e'
            break
          case 'technology':
            categoryIcon = 'mdi-alpha-c-box'
            break
        }
        return categoryIcon
      },
      isLoading () {
        // console.log(this.$store.getters['collaborators/getLoading'])
        return this.$store.getters['collaborators/getLoading']
      },
    },
    created () {
      vm = this
      // const database = firebase.database();
      // const storage = firebase.storage();
    },
    methods: {

      createItem: function () {
        const vm = this
        vm.model.adminUid = vm.$store.state.users.user.uid
        const itemData = vm.model
        axios.post(vars.urlProd + '/createCollaborator', itemData).then(res => {
          vm.$store.commit('collaborators/SET_LOADING', false)
          vm.onSuccess()
        }).catch(e => {
          // console.log(e.response)
          if (e.response) {
            vm.message = e.response.data.error.message
            vm.show = true
          }
          vm.$store.commit('collaborators/SET_LOADING', false)
        })
      },
      refresh () {
        this.model = {
          uid: null,
          name: null,
        }
      },
      onSuccess () {
        this.refresh()
        this.$emit('close')
      },
      cancel () {
        this.refresh()
        this.$emit('close')
      },
      setImage (image) {
        vm.model.imagen = image
        // console.log(vm.model.imagen);
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .ql-video {
        max-width: 100%;

    }
    img{
        max-width: 100%;
        height:auto;
    }
</style>
