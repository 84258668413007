<template lang="pug">
  v-card
    v-card-title
      | Update Collaborator
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.body-1.text-center
      v-row
        v-col(cols="12" md="12")
            v-layout
                formStepper(:model ='model', :field='field' :onSave='updateItem' :context='currentContext' @cancel="cancel")
</template>
<script>
  import Stepper from '../../../components/custom/steppers/UserStepper.vue'
  export default {
    components: {
      formStepper: Stepper,
    },
    props: {
      model: Object,
    },
    data: () => ({
      field: {
        types: 'update',
      },
      currentContext: 'collaborator',

    }),
    computed: {
      icon () {
        const category = this.model.category
        let categoryIcon = null
        switch (category) {
          case 'nation':
            categoryIcon = 'mdi-alpha-n-box'
            break
          case 'economy':
            categoryIcon = 'mdi-alpha-e-box'
            break
          case 'life':
            categoryIcon = 'mdi-alpha-v-box'
            break
          case 'international':
            categoryIcon = 'mdi-alpha-i-box'
            break
          case 'trending':
            categoryIcon = 'mdi-alpha-v'
            break
          case 'sports':
            categoryIcon = 'mdi-alpha-d-box'
            break
          case 'entertainment':
            categoryIcon = 'mdi-alpha-e'
            break
          case 'technology':
            categoryIcon = 'mdi-alpha-c-box'
            break
        }
        return categoryIcon
      },
    },
    created () {
      // vm = this
      // const database = firebase.database();
      // const storage = firebase.storage();
    },
    methods: {
      updateItem () {
        // A post entry.
        const vm = this
        const itemData = vm.model
        // console.log(itemData)
        // this.$store.commit('setLoading', true);
        this.$forceUpdate()
        setTimeout(function () {
          // console.log(this.currentContext, itemData)
          this.$store.dispatch('collaborators/updateItem', itemData).then(() => {
            vm.onSuccess()
          })
        }.bind(this), 1000)
      },
      onSuccess () {
        // this.$router.push({name: 'PropertyList'});
        this.$emit('close')
      },
      cancel () {
        this.$emit('close')
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .ql-video {
        max-width: 100%;

    }
    img{
        max-width: 100%;
        height:auto;
    }
</style>
